<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <div class="d-flex justify-content-between align-items-center px-4 pt-4">
              <h3 class="card-title font-weight-bold" style='font-size: 30px'>Cuentas</h3>
              <vs-button @click="$router.push('CrearCuenta')">Crear Cuenta</vs-button>
            </div>
          </template>
          <div class='p-4 mb-3'>
            <template>
              <div class='mb-1'>
                <div v-for='(item, index) in tipos' :key='index'>
                  <div class="d-flex flex-row justify-content-between align-items-center animateborder"
                    style="cursor: pointer;" @click="toggleCollapse(index)">
                    <h3 class="font-weight-bold borderani" style='font-size: 20px'>{{ item }}</h3>
                    <h2 v-show="isCollapsed[index]"><i class="fa-solid fa-caret-down"></i></h2>
                    <h2 v-show="!isCollapsed[index]"><i class="fa-solid fa-caret-up"></i></h2>
                  </div>
                  <transition name="fade">
                    <div v-show="!isCollapsed[index]" class="pt-3 pb-3 row justify-content-center">
                      <Table :clase='item' />
                    </div>
                  </transition>
                  <hr style='height: 1px; background: var(--dark);' class='w-100 mb-3' />
                </div>
              </div>
            </template>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { core } from '../../../../../../config/pluginInit';
  import Table from '../Components/Table.vue';
  export default {
    components: {
      Table
    },
    data: () => ({
      isCollapsed: {},
      tipos: [],
    }),
    async mounted() {
      core.index();
      await this.getData();
    },
    methods: {
      toggleCollapse(index) {
        this.$set(this.isCollapsed, index, !this.isCollapsed[index]);
      },
      async getData() {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: 'DEClaseCuenta/ObtenerClaseCuenta',
          });
          this.tipos = res.data.map(data => data.NombreClase);
        } catch (error) {
          this.tipos = [];
        }
      }
    }
  }
</script>

<style>
  .header-title {
    width: 100% !important;
  }
</style>