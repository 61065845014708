<template>
  <card class='px-4 shadow-none w-100' style='background: transparent !important;'>
    <div class="center p-3">
      <vs-table>
        <template #header>
          <vs-input v-model="search" border placeholder="Search" />
        </template>
        <template #thead>
          <vs-tr>
            <vs-th style='min-width: 10rem; cursor: pointer;' @click.prevent='ordenar(1)'>
              Codigo
            </vs-th>
            <vs-th style='min-width: 15rem; cursor: pointer;' @click.prevent='ordenar(2)'>
              Nombre
            </vs-th>
            <vs-th style='min-width: 15rem; cursor: pointer;' @click.prevent='ordenar(3)'>
              Tipo
            </vs-th>
            <vs-th style='min-width: 12rem; cursor: pointer;' @click.prevent='ordenar(4)'>
              Saldo
            </vs-th>
            <vs-th style='max-width: 8rem'>
              Opciones
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="i" v-for="(tr, i) in datos" not-click-selected open-expand-only-td>
            <vs-td>
              {{ tr.codigo }}
            </vs-td>
            <vs-td>
              {{ tr.nombre }}
            </vs-td>
            <vs-td>
              {{ tr.tipo }}
            </vs-td>
            <vs-td>
              {{ tr.saldo }}
            </vs-td>
            <vs-td>
              <div class='d-flex justify-content-center' style='gap: .5rem;'>
                <b-button class='bg-success border-0' title='Ver' @click="$router.push(`DetallesCuenta/${tr.id}`)">
                  <ion-icon name="document-outline"></ion-icon>
                </b-button>
                <b-button class='bg-primary border-0' v-b-tooltip.hover title='Editar'
                  @click="$router.push(`EditarCuenta/${tr.id}`)">
                  <ion-icon name="create-outline"></ion-icon>
                </b-button>
                <b-button @click.prevent='deleteCuenta(tr.id)' class='bg-danger border-0' v-b-tooltip.hover
                  title='Eliminar'>
                  <ion-icon name="trash-outline"></ion-icon>
                </b-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #notFound>
          <div v-if='loading' class='w-100 d-flex justify-content-center align-items-center' style='height: 10rem;'>
            Buscando registros
          </div>
        </template>
        <template #footer>
          <div class='d-flex justify-content-between align-items-center'>
            <b-form-select style='width: 4rem;' placeholder='-- 10 --' :options="options" v-model='max' required
              value='10'>
            </b-form-select>
            <vs-pagination v-model="page" :length="totalpagues" />
          </div>
        </template>
      </vs-table>
    </div>
  </card>
</template>

<script>
  import Swal from 'sweetalert2';
  // import { debounce } from 'lodash/debounce';
  import { core } from '../../../../../../config/pluginInit';

  export default {
    props: {
      clase: {
        type: String,
        required: true
      }
    },
    data: () => ({
      Registro: [],
      PersonaId: null,
      search: '',
      page: 1,
      totalpagues: 0,
      max: 10,
      loading: false,
      columna: 1,
      ascendente: true,
      options: [
        {
          value: 5,
          text: '5'
        },
        {
          value: 10,
          text: '10'
        }, {
          value: 20,
          text: '20'
        }, {
          value: 50,
          text: '50'
        }, {
          value: 100,
          text: '100'
        }
      ],
      datos: []
    }),
    async mounted() {
      const Persona = JSON.parse(localStorage.getItem('credenciales'));
      this.PersonaId = Persona.personaId;

      core.index();
      await this.getData();
    },
    watch: {
      // search: {
      //   handler: debounce(function () {
      //     this.getData();
      //   }, 700),
      //   immediate: false
      // },
      search() {
        this.getData();
      },
      max() {
        this.getData();
      },
      page() {
        this.getData();
      },
      totalpagues() {
        this.getData();
      },
      columna() {
        this.getData();
      },
      ascendente() {
        this.getData();
      }
    },
    methods: {
      async deleteCuenta(id) {
        await Swal.fire({
          title: '¿Estás seguro que deseas eliminar este Movimiento?',
          text: "¡No podrás revertir esto!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, borrarlo!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              let response = await this.$store.dispatch("hl_delete", {
                path: `DESubCuenta/EliminarSubCuenta/${id}/personaId/${this.PersonaId}`,
              });
              if (response.respuesta) {
                await this.getData();
                Swal.fire("Listo!", "Cuenta eliminada correctamente.", "success");
              } else if (response.respuesta == false) {
                Swal.fire("Error", "la cuenta tiene movimientos asociados", "error");
              }
            } catch (error) {
              Swal.fire("Error", "Hubo un problema al eliminar la cuenta.", "error");
            }
          }
        });
      },
      async getData() {
        this.datos = [];
        let send = {
          filtro: this.search,
          orden: {
            columna: this.columna,
            ascendente: this.ascendente
          },
          cantDatos: this.max,
          paginacion: this.page
        }
        try {
          this.loading = true;
          let cantidadDatos = await this.$store.dispatch("hl_get", {
            path: `DESubCuenta/consultarCantidadSubCuentasEmpresa/${this.PersonaId}?tipo=${this.clase}&filtro=${this.search}`,
          });

          this.totalpagues = Math.ceil(cantidadDatos.data / this.max);
          if (cantidadDatos.data <= this.max) {
            this.page = 1;
          }

          let response = await this.$store.getters.fetchPost({
            path: `DESubCuenta/ObtenerSubCuentasEmpresa/${this.PersonaId}?tipo=${this.clase}`,
            data: send
          });

          if (response.ok) {
            const data = await response.json();
            if (data.data.length > 0) {
              this.datos = data.data.map((item) => ({
                id: item.Id,
                codigo: item.PUC,
                nombre: item.nombre,
                tipo: item.Clase,
                saldo: item.saldo
              }))
            } else {
              this.response = [];
            }
          } else {
            console.error('Error en la solicitud:', response.statusText);
          }
        } catch (error) {
          this.response = [];
        } finally {
          this.loading = false;
        }
      },
      ordenar(columna) {
        if (this.columna === columna) {
          this.ascendente = !this.ascendente;
        } else {
          this.columna = columna;
          this.ascendente = true;
        }
        this.getData();
      }
    }
  }
</script>

<style>
  .header-title {
    width: 100% !important;
  }

  .vs-table__header,
  .vs-table__footer {
    background: var(--body-bg);
  }

  .vs-input {
    width: 100%;
  }
</style>